<template>
  <div class="language-navigation relative cursor-pointer ml-auto">
    <button class="uppercase text-green font-bold" @click="showModal">
      {{ currentLang }}
      <i class="fas fa-angle-down font-icons text-icon-small text-green"></i>
    </button>
  </div>
  <teleport to="body">
    <div ref="modal" class="modal fixed top-0 left-0 h-screen w-screen hidden bg-bg_creme_light z-50">
      <div class="modal-head flex justify-end px-5 pt-4 absolute z-20 top-0 left-0 w-full">
        <button @click="hideModal">
          <i class="fas fa-times font-icons text-icons text-green"></i>
        </button>
      </div>
      <div class="modal-body flex items-center h-full w-full absolute z-10 top-0 left-0">
        <div class="modal-body--inner w-full px-5">
          <button class="btn btn-green mb-2.5" v-for="(lang, key) in langs" :key="`Lang${key}`" @click="SwitchLang(key)">
            {{ lang }}
          </button>
        </div>
      </div>
    </div>
  </teleport>
</template>

<script>
import eventService from "@/services/eventService";

export default {
  name: 'LangNav',
  data () {
    return {
      currentLang: '',
      langs: {
        'de': 'Deutsch',
        'fr': 'Français'
      }
    }
  },
  created() {
    this.currentLang = this.$root.$i18n.locale;
  },
  methods: {
    SwitchLang(lang) {
      this.$root.$i18n.locale = lang;
      this.currentLang = lang;
      eventService.$emit('lang-switch', lang);
      this.hideModal();
    },
    showModal() {
      const el = this.$refs.modal;
      el.classList.remove('hidden');
    },
    hideModal() {
      const el = this.$refs.modal;
      el.classList.add('hidden');
    },
    getCurrentLang() {
      return this.currentLang;
    }
  }
}
</script>
